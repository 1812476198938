import { cutoffHour, cutoffMinutes } from '../../../config/config/settings'
import skippedHolidays from '../../../config/helpers/skippedHolidays'
// import Holidays from 'date-holidays'
// const hd = new Holidays('US')

function isUSHoliday(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JS months are 0-based
    const day = date.getDate();

    // New Year's Day
    if (month === 1 && day === 1) return true;

    // Memorial Day: last Monday in May
    const memorialDay = new Date(year, 4, 31); // May 31
    while (memorialDay.getDay() !== 1) {
        memorialDay.setDate(memorialDay.getDate() - 1);
    }
    if (date.toDateString() === memorialDay.toDateString()) return true;

    // // Juneteenth National Independence Day
    // if (month === 6 && day === 19) return true;

    // Independence Day
    if (month === 7 && day === 4) return true;

    // Labor Day: 1st Monday in September
    const laborDay = new Date(year, 8, 1); // September 1
    while (laborDay.getDay() !== 1) {
        laborDay.setDate(laborDay.getDate() + 1);
    }
    if (date.toDateString() === laborDay.toDateString()) return true;

    // // Columbus Day: 2nd Monday in October
    // const columbusDay = new Date(year, 9, 1); // October 1
    // let mondayCount = 0;
    // while (mondayCount < 2) {
    //     if (columbusDay.getDay() === 1) {
    //         mondayCount++;
    //     }
    //     if (mondayCount < 2) {
    //         columbusDay.setDate(columbusDay.getDate() + 1);
    //     }
    // }
    // if (date.toDateString() === columbusDay.toDateString()) return true;

    // // Veterans' Day
    // if (month === 11 && day === 11) return true;

    // Thanksgiving Day: 4th Thursday in November
    const thanksgiving = new Date(year, 10, 1); // November 1
    let thursdayCount = 0;
    while (thursdayCount < 4) {
        if (thanksgiving.getDay() === 4) {
            thursdayCount++;
        }
        if (thursdayCount < 4) {
            thanksgiving.setDate(thanksgiving.getDate() + 1);
        }
    }
    if (date.toDateString() === thanksgiving.toDateString()) return true;

    // Christmas Day
    if (month === 12 && day === 25) return true;

    // If none of the conditions match, it's not a holiday
    return false;
}

let latestCutoff = function (day = new Date()) {

    const currentCentralTime = new Date(day).toLocaleString("en-US", { timeZone: "America/Chicago" });
    const currentDate = currentCentralTime.split(",")[0];
    const currentTime = currentCentralTime.split(",")[1].trim().split(" ")[0];

    let currentHour = parseInt(currentTime.split(":")[0], 10);
    let currentMinute = parseInt(currentTime.split(":")[1], 10);

    if (currentCentralTime.includes("PM") && currentHour !== 12) currentHour += 12;

    let targetDate;
    if (currentHour < cutoffHour || (currentHour === cutoffHour && currentMinute < cutoffMinutes)) {
        const tempDate = new Date(currentCentralTime);
        tempDate.setDate(tempDate.getDate() - 1);
        targetDate = tempDate.toLocaleString("en-US", { timeZone: "America/Chicago" }).split(",")[0];
    } else {
        targetDate = currentDate;
    }

    // Adjusting here to include cutoffMinutes in the final Date object
    let startRange = new Date(`${targetDate} ${cutoffHour}:${cutoffMinutes}:00`);

    let isBusinessDay = false
    while (!isBusinessDay) {
        let isSunday = startRange.getDay() == 0
        let isHoliday = isUSHoliday(new Date(startRange))
        isBusinessDay = !isSunday && !isHoliday
        if (!isBusinessDay) startRange = new Date(startRange - 1000 * 60 * 60 * 24 * 1)
    }

    return startRange
}

export default latestCutoff
