import skippedHolidays from '../helpers/skippedHolidays.json'
import settings from '../config/settings.json'
import Holidays from 'date-holidays'
const hd = new Holidays('US')

let latestCutoff = function (day = new Date()) {

    const currentCentralTime = new Date(day).toLocaleString("en-US", { timeZone: "America/Chicago" });
    const currentDate = currentCentralTime.split(",")[0];
    const currentTime = currentCentralTime.split(",")[1].trim().split(" ")[0];

    let currentHour = parseInt(currentTime.split(":")[0], 10);
    let currentMinute = parseInt(currentTime.split(":")[1], 10);

    if (currentCentralTime.includes("PM") && currentHour !== 12) currentHour += 12;

    let targetDate;
    if (currentHour < settings.cutoffHour || (currentHour === settings.cutoffHour && currentMinute < settings.cutoffMinutes)) {
        const tempDate = new Date(currentCentralTime);
        tempDate.setDate(tempDate.getDate() - 1);
        targetDate = tempDate.toLocaleString("en-US", { timeZone: "America/Chicago" }).split(",")[0];
    } else {
        targetDate = currentDate;
    }

    // Adjusting here to include settings.cutoffMinutes in the final Date object
    let startRange = new Date(`${targetDate} ${settings.cutoffHour}:${settings.cutoffMinutes}:00`);

    let isBusinessDay = false
    while (!isBusinessDay) {
        let isSunday = startRange.getDay() == 0
        let holidays = hd.isHoliday(new Date(startRange))?.filter?.(h => h.type == 'public' && !skippedHolidays.includes(h.name))
        let isHoliday = !!holidays?.length
        isBusinessDay = !isSunday && !isHoliday
        if (!isBusinessDay) startRange = new Date(startRange - 1000 * 60 * 60 * 24 * 1)
    }

    return startRange

}

export default latestCutoff
